/** @define configurator; weak */
.configurator__links {
  padding: 30px 0;
  background-color: #090a0c;
  border-bottom: 1px solid #3a3b41;
}
.configurator__links .link {
  height: 50px;
  margin: 15px;
  padding: 0 50px;
  color: #ffffff;
  text-transform: uppercase;
  white-space: nowrap;
  background-color: #f59a00;
  border-radius: 5px;
  box-shadow: 0 -10px 25px -19px #f5b300;
  transition: background-color 0.4s ease-in-out;
}
.configurator__links .link:hover {
  text-decoration: none;
  background-color: #229660;
}
.configurator__links .icon_svg {
  margin-right: 20px;
}
.configurator__links .icon_car {
  width: 42px;
  height: 15px;
}
.configurator__links .icon_gear {
  width: 32px;
  height: 32px;
}
.configurator__info {
  padding: 50px;
  color: #e1e0d8;
  background-color: #14161d;
}
.configurator__products iframe,
.configurator__accessories iframe {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 740px;
}
.configurator__accessories iframe {
  min-height: 700px;
}
/*# sourceMappingURL=css/configurator.css.map */